<template>
    <div class="relative h-100" v-if="periods && periods.length > 0">
        <!-- PERIOD BUTTON -->
        <div
            class="menu-button"
            :class="[
                opened ? 'menu-button--active' : '',
                highlight ? 'menu-button__highlight' : '',
                small ? 'menu-button__small' : '',
            ]"
            @click="toggleMenu"
        >
            <i class="fa-solid fa-calendar mr-2" v-if="period.locked === 0"></i>
            <i class="fas fa-lock mr-2" v-else></i>
            {{ period.name }}
        </div>

        <!-- PERIOD MENU -->
        <div
            class="menu-select__wrapper"
            :class="opened ? 'menu-select__wrapper--visible' : ''"
        >
            <!-- MAIN PERIOD ITEMS -->
            <div class="flex flex-column">
                <div
                    class="menu-items__wrapper"
                    id="period-select"
                    :class="opened ? 'menu-items__wrapper--visible' : ''"
                >
                    <!-- PERIOD OPTIONS -->
                    <div
                        v-for="period in periods"
                        :key="period.value"
                        class="menu-select__item"
                        @click="selectPeriod(period.id)"
                        :class="
                            currentPeriod === period
                                ? 'menu-select__item--selected'
                                : ''
                        "
                    >
                        <span>
                            <i
                                class="fas fa-calendar-alt mr-3"
                                v-if="period.locked === 0"
                            ></i>
                            <i class="fas fa-lock mr-3" v-else></i>
                            {{ period.name }}
                            <i
                                class="fa-solid fa-circle menu-select__indicator"
                                v-if="currentPeriod === period.value"
                            ></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";
import { mapState } from "vuex";

export default {
    name: "CCPeriods",
    props: {
        highlight: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            opened: false,
            shiftLeft: true,
        };
    },
    computed: {
        ...mapState({
            periods: (state) => state.data.periods,
            currentPeriod: (state) => state.filters.period,
        }),
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.periods) return;
            if (this.periods.length === 0) return;

            try {
                // This value gets reset when switching companies. We get the currently set period and see if the ID
                // is included in the list of periods. If it is, we set it as the current period. If not, we set the
                // last period in the list as the current period.
                const currentValue = +localStorage.getItem("period");
                const indexOfPeriod = this.periods.findIndex(
                    (x) => x.id === currentValue
                );

                if (currentValue && indexOfPeriod !== -1) {
                    // Don't actively re-set the current period if it's already set (prevents needless re-renders down
                    // the line)
                    if (currentValue === this.currentPeriod) return;
                    this.selectPeriod(currentValue, true);
                } else {
                    this.selectPeriod(
                        this.periods[this.periods.length - 1].id,
                        true
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        toggleMenu() {
            this.opened = !this.opened;
            if (this.opened === false) this.selected = "";
            if (this.opened === true) {
                this.checkPosition();
                this.$emit("opened", "periods");
            }
        },
        closeMenu() {
            this.opened = false;
            this.selected = "";
        },
        selectPeriod(id, automated = false) {
            this.$store.dispatch(types.SET_FILTER, { period: id });
            if (automated === false) this.toggleMenu();
        },
        checkPosition() {
            const options = {
                root: document.querySelector(".main-content"),
                rootMargin: "0px",
                threshold: 1.0,
            };
            const target = document.getElementById("period-select");
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) this.shiftLeft = true;
                });
            }, options);

            observer.observe(target);
        },
    },
    watch: {
        periods: {
            handler() {
                this.init();
            },
        },
    },
};
</script>
